/* HTML TAGS ***********************************/

html, body, div, td, span {
  font-family: proxima-nova;
  color: #3e3e3e;
  font-size: 1em;
}

h1, h2,h3,h4,h5,h6 {
  font-family: proxima-bold;
  color: #3e3e3e;
}

body {
  background: #F8F8F5;
}

h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h5 { font-size: .83em; }
h6 { font-size: .75em; }

b {font-family: proxima-bold;
  color: #3e3e3e;}

a, a:visited { color: #4c8d2b;
  text-decoration: none;
  cursor: pointer;
}

a:hover, a:active {
  text-decoration: underline;
  color: #4c8d2b;
  pointer:hand;
}


/* BOOTSTRAP FORM CUSTOMIZATION *****************/

.btn-primary {
  background: #78BE21;
  border: 0px;
  font-family: proxima-nova;
  padding:  0.2em 1em 0.2em 1em;
  border-radius: 4px;
}

.btn-primary:disabled {
  background: #cfcfcf;
}

.btn-primary:hover {
  background: #4C8D2B;
}

.btn-primary:active {
  background: #4C8D2B;
}

.btn-secondary {
  background: #959595;
  border: 0px;
  font-family: proxima-nova;
  padding:  0.2em 1em 0.2em 1em;
  border-radius: 4px;
}

.form-check-input:checked {
  background: #78BE21;
  border: 0px;
}


/* LAYOUT STYLES ********************************/

.solvenagreen {
  color: #4C8D2B!important
}

.footerSpace {
  margin-bottom: 120px;
}

footer.solvena-bottom-toolbar {
  bottom: 20px;
}

.mandatoryFooter {
  color: white;
  background: #4C8D2B;
  text-align:right;
  height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 20px;
  font-size: 12px;

}

.homepagetitle {
  font-family: proxima-bold;
  font-size: 1.3em;
  color: #3e3e3e;
}

.solvenatable {
  font-size: 0.8em;
}

.solvenatable>.header>div {
  font-family: proxima-bold;
}

.solvenabold {
  font-family: proxima-bold;
}

.solvenatabl>div>select {
  font-size: 0.8em;
}

.form-control-small {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.smallLabel {
  font-size: 0.8em
}

nav.mainnav {
  background: #4C8D2B;
  color: #fff;
  font-size: 0.8em;
  height: 30px;
  padding-top: 4px;
  padding-bottom: 0px;
}

div.mainnav {
  background: #4C8D2B;
  color: #fff;
}

div.mainnav li {
  font-size: 1em;
}

div.mainnav li:hover {
  background: #F8F8F5;
  color: #4C8D2B;
}

div.mainnav li a {
  color: white;
  cursor: pointer;
}
div.mainnav li a:hover {
  color: #4C8D2B;
}

nav.solvena {
  height: 25px;
  padding-top: 10px;
}

nav li {
  color: white;
  padding-right: 0.75em;
  padding-left: 0.75em;
  margin-right:0.25em;
  text-transform:uppercase;
  cursor:hand;
}

nav li.active {
  background: rgba(186,222,122,0.7);
  border-radius: 4px 4px 0px 0px;
  margin-bottom: -5px;
}

nav li:hover {
  background: white;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: -5px;
  cursor: hand;
}


nav li.active a {
  /*color: #4c8d2b;*/
  /*font-family: proxima-bold;*/
}


nav a {
  color: white;
  text-decoration: none;
  cursor:hand;
}

.navbar-text {
  font-size: 1.17em!important;
}

.appnavigation {
  border: 1px solid #4C8D2B;
  background: white;
  color: #4C8D2B;
  z-index: 9999;
  margin-top: 0px;
  right: 0px
}

.appnavigation li {
  color: #4C8D2B;
  margin-bottom: -5px;
  margin-top: 5px;
}

.appnavigation li:hover {
  color: white;
  background: #4C8D2B
}

.appnavigation li img {
  border:white 1px solid;

}
.toolNavigationIcon {
  width: 20px;
  margin-right: 10px;
}

footer {
  background: white;
  border-top: solid 1px #F8F8F5;
}

.statusbartitle {
  font-family: proxima-bold;
  text-transform:uppercase;
  font-size:1em;
}

.darktext, .darktext div {
  color: #3e3e3e;
}
.producttitle {
  font-family: proxima-bold;
  text-transform:uppercase;
  font-size:1.2em;
}

.solvenapagetitle {
  font-family: proxima-bold;
  text-transform: uppercase;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.6em;
}

.card {
  border: 0px;
  padding: 0px;
}

.card-header {
  border: 0px;
  padding: 0px;
  padding-left: 4px;
  font-family: proxima-bold;
  text-transform: uppercase;
  color: #7e7e7e;
}

.card-header.homepage {
  border: 0px;
  padding: 0px;
  padding-left: 4px;
  font-family: proxima-bold;
  text-transform: uppercase;
  color: white;
  background-color: #4C8D2B;
}

.card-header div {
  font-family: proxima-bold;
  text-transform: uppercase;
  color: #7e7e7e;
}

.card-header .row div a, .card-header .row div a:active,.card-header .row div a:visited  {
  font-family: proxima-bold;
  text-transform: uppercase;
  color: #4c8d2b;
  text-decoration: none;
}

.card-body {
  background: white;
  color: #4c8d2b;
  border-radius: 4px;
  font-size: 0.8em;
}

.card-body span, .card-body div {
  /*color: #4c8d2b;*/
  color: #3e3e3e;
}

.card-body .toolname-small {
  font-family: proxima-bold;
  text-transform: uppercase;
  color: #4c8d2b;
}

.card-body .card-item {
  background: #F8F8F5;
  border-radius: 4px;
}

.card-item-white {
  background: white;
  color: #4c8d2b;
  border-radius: 4px;
  margin-bottom: 10px;
}

.card-body.dashboardstats {
  font-family: proxima-bold;
  font-size: 1em;
  font-weight: bold
}

.card-title {
  font-family:proxima-bold
}

.card-body label {
  color: #3e3e3e;
  font-size: 0.8em;
}

.highlight, .highlight div {
  background: #8ABD24!important;
  color: white!important;
}

.transparentOnWhite {
  background: #F8F8F5;
}

span.functiontag {
  background: #3e3e3e;
  color: white;
  padding: 0px 10px 0px 10px;
  margin-right: 10px;
  border-radius: 4px;
}
span.tooltag {
  background: #4c8d2b;
  color: white;
  padding: 0px 10px 0px 10px ;
  margin-right: 10px;
  border-radius: 4px;
}

.labelindent {
  display: inline-block;
  margin-left: 20px;
}

.labelindent:before{
  margin-left: -23px;
}

.show > .dropdown-menu {
  display: block;
  color:black
}

.usermenu {
  background: #4C8D2B;
  margin-left: -0.5em;
}

.usermenu li {
  color: white;
  font-size: 0.8em!important;
}

.usermenu li:hover {
  background: white;
  color: #4C8D2B;
  border-radius:0px;
  margin: 0px;
  padding-bottom: 0px;

}

/* --- Progress Navigation ---- */

ul.kcprogress {
  list-style-image: url('../images/Bullet.png');
  background: white;
}

ul.kcprogress li.kcprogressActive{
  list-style-image: url('../images/BulletActive.png');
  color: #4c8d2b;
  font-family: proxima-bold;
}

ul.kcprogress li.kcprogressActivated {
  list-style-image: url('../images/BulletActivated.png');
  color: #4c8d2b;
  font-family: proxima-nova;
}

ul.kcprogress li::marker {
  padding-top:4px;
}

.card-body {
  border-top: 3px solid #8ABD24;
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0px;
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0px;
  box-shadow: 4px 4px 10px #eee;
}


