@font-face {
  font-family: proxima-nova;
  src: url(../fonts/ProximaNovaRegular.ttf) format("truetype");
}

@font-face {
  font-family: proxima-bold;
  src: url(../fonts/ProximaNovaBold.ttf) format("truetype");
}

